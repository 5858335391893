<script>
import * as echarts from 'echarts';
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import gpselect from "../../components/gpselect";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import { categories} from "./data";
import { queryCalendar,saveEvent,deleteEvent } from  '@/apis/calendar';
import { formatDate,checkRole} from  '@/apis/common';
import {queryStaffList} from '@/apis/user'
import Switches from "vue-switches";
import store from '@/state/store'
/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    DatePicker,
    Switches,
    gpselect
  },
  data() {
    return {
      viewTitle:"Calendar",
      curView: "dayGridMonth",
      users:"",
      auditList:[],
      calendarEvents: [],
      calendarApi:null,
      calendarOptions: {
        locale: 'en',
        // headerToolbar: {
        //   left: "prev,next today",
        //   center: "title",
        //   right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        // },
        headerToolbar: false,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin
        ],
        firstDay:1,
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        select:this.selectDate,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: (info, successCallback, failureCallback) =>{
          let params = {users:this.users==""?[]:this.users.split(",")}
          params.start = formatDate(info.startStr,"yyyy-MM-dd")
          params.end = formatDate(info.endStr,"yyyy-MM-dd")
          queryCalendar(params).then((res)=>{
            let events = []
            res.data.forEach(item=>{
              let className = "bg-info text-white"
              if(item.isSystem&&item.pending&&!item.isTravel){
                className = "bg-success text-white"
              }else if(item.isSystem&&!item.pending&&!item.isTravel){
                className = "bg-info text-white"
              }else if(item.isSystem&&!item.pending&&item.isTravel){
                className ='bg-warning text-white'
              }else if(item.isSystem&&item.pending&&item.isTravel){
                className ='bg-success  text-white'
              }else{
                className ='bg-dark text-white'
              }
              let event = {...item,start:new Date(item.start),end:new Date(item.end),className: className}
              events.push(event)
            })
            successCallback(events)
          }).catch((res)=>{
            failureCallback(res.message);
          })
        }
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        isShare: false,
      },
      editevent: {
        title: "",
        isShare: false,
      },
      charts:{
        auditorBar:null,
        workDayTrendChart:null
      },
      frequencys:[
        {value:"W",label:"周"},
        {value:"M",label:"月"}
      ],
      analysisParams:{
        startDate:"",
        endDate:"",
        frequency:"W",
        auditor:""
      }
    };
  },
  validations: {
    event: {
      title: { required },
    },
    analysisParams:{
      endDate:{ required },
      startDate:{ required }
    }
  },
  computed:{
    title(){
      return this.$t("menu.calendar")
    },
    isSystem(){
      
      if(this.edit!=null && this.edit.extendedProps!=null){
        return this.edit.extendedProps.isSystem || store.state.auth.currentUser.userName!=this.edit.extendedProps.userName
      }else{
        return false
      }
    },
    isAdmin(){
      return checkRole("SYS_ADMIN,CS")
    }
  },
  watch:{
    users(){
      this.calendarApi.refetchEvents()
    }
  },
  mounted(){
    this.calendarApi = this.$refs.fullCalendar.getApi();
    this.viewTitle = this.calendarApi.view.title
  },
  created(){
    queryStaffList({roles:["AUDIT"]}).then((res)=>{
            if(this.isAdmin){
              this.auditList = res.data.map(item=>{ return {value:item.userName,label:item.staffName,labelEn:item.staffName}});
            }else{
              this.auditList = [{value:this.$store.state.auth.currentUser?.userName,label:"自己",labelEn:"My-self"},{value:"jack",label:"Jack",labelEn:"Jack"}]
            }
        })
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.event.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const isShare = this.event.isShare;
        const category = "bg-success";
        let start = formatDate(this.newEventData.startStr,"yyyy-MM-dd hh:mm:ss");
        let end = formatDate(this.newEventData.endStr,"yyyy-MM-dd hh:mm:ss");
        if(this.newEventData.startStr==undefined){
          start = formatDate(this.newEventData.dateStr,"yyyy-MM-dd hh:mm:ss");
          end = formatDate(this.newEventData.dateStr,"yyyy-MM-dd hh:mm:ss");
        }
        let eventData = {
          title,
          isShare,
          allDay:this.newEventData.allDay,
          start,
          end,
          classNames: [category]
        }
        saveEvent(eventData).then(()=>{
          this.successmsg();
          this.showModal = false;
          this.newEventData = {};
          this.calendarApi.refetchEvents()
        })
      }
      this.submitted = false;
      this.event = {
        title: "",
        isShare: false
      };
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {
        title: "",
        isShare: false
      };
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const title = this.editevent.title;
      const isShare = this.editevent.isShare;
      this.edit.setProp("title", title);
      this.edit.setProp("isShare", isShare);
      let eventData = {
        id:this.edit.id,
        title,
        isShare
      }
      saveEvent(eventData).then(()=>{
        this.successmsg();
        this.eventModal = false;
        this.calendarApi.refetchEvents()
      })
    },

    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    selectDate(info){
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      if(info.event.extendedProps.isTravel===true)return;
      this.editevent.title = this.edit.title;
      this.editevent.isShare = this.edit.extendedProps.isShare;
      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },
    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          deleteEvent(this.edit.id).then((res)=>{
            if(res.data){
              this.edit.remove();
              this.eventModal = false;
              Swal.fire("Deleted!", "Event has been deleted.", "success");
              this.calendarApi.refetchEvents()
            }else{
              Swal.fire("Error!", "Event delete error.", "error");
            }
          })
        }
      });
    },
    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    monthView(){
      this.curView = "dayGridMonth"
      this.$nextTick(() => {
        this.calendarApi.changeView(this.curView)
        this.viewTitle = this.calendarApi.view.title
      })      
    },
    weekView(){
      this.curView = "timeGridWeek"
      this.$nextTick(() => {
        this.calendarApi.changeView(this.curView)
        this.viewTitle = this.calendarApi.view.title
      })      
    },
    dayView(){
      this.curView = "timeGridDay"
      this.$nextTick(() => {
        this.calendarApi.changeView(this.curView)
        this.viewTitle = this.calendarApi.view.title
      })      
    },
    listView(){
      this.curView = "listMonth"
      this.$nextTick(() => {
        this.calendarApi.changeView(this.curView)
        this.viewTitle = this.calendarApi.view.title
      })      
    },
    analysisView(){
      this.curView = "analysis"
      this.$nextTick(() => {
        this.loadAnalysisData()
      })
    },
    resizeChart(){
      if(this.charts.auditorBar!=null){
          this.charts.auditorBar.resize()
          this.charts.workDayTrendChart.resize()
      }
    },
    loadAnalysisData(){
      if(this.charts.auditorBar==null){
        this.charts.auditorBar = echarts.init(document.getElementById('chart1'));
        this.charts.workDayTrendChart = echarts.init(document.getElementById('chart2'));
        window.addEventListener("resize", () => {
          this.resizeChart()
        })
      }
      this.loadAuditorBar()
      this.loadWorkDayTrendChart()
      this.resizeChart()
    },
    loadAuditorBar(){
      this.charts.auditorBar.setOption({
        title: {
          text: '审核员排期情况',
          left:'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          y: 'bottom', x: 'center' 
        },
        xAxis: {
          data: ['Jack', 'Carol', 'Huey']
        },
        yAxis: {},
        series: [
          {
            name: '验证',
            type: 'bar',
            barWidth: 10,
            stack: 'Ad',
            data: [5, 20, 36]
          },
          {
            name: '差旅',
            type: 'bar',
            stack: 'Ad',
            barWidth: 10,
            data: [10, 10, 20]
          },
          {
            name: '空闲',
            type: 'bar',
            barWidth: 10,
            stack: 'Ad',
            data: [3, 2, 1]
          }
        ]
      });
    },
    loadWorkDayTrendChart(){
      this.charts.workDayTrendChart.setOption({
  title: {
    text: '排期走势',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['Jack', 'Carol', 'Huey'],
    y: 'bottom', x: 'center' 
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['2023/01', '2023/02', '2023/03', '2023/04', '2023/05', '2023/06', '2023/07']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: 'Jack',
      type: 'line',
      stack: 'Total',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: 'Carol',
      type: 'line',
      stack: 'Total',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: 'Huey',
      type: 'line',
      stack: 'Total',
      data: [150, 232, 201, 154, 190, 330, 410]
    },
  ]
})
    },
    // 今天
    getToday() {
      this.calendarApi.today();
      this.viewTitle = this.calendarApi.view.title
    },
    // 上一月
    getPrev() {
      this.calendarApi.prev();
      this.viewTitle = this.calendarApi.view.title;
    },
    // 下一月
    getNext() {
      this.calendarApi.next();
      this.viewTitle = this.calendarApi.view.title;
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ title }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="fc fc-media-screen fc-direction-ltr fc-theme-bootstrap">
              <div class="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                <div class="fc-toolbar-chunk" >
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary" @click="getPrev"><span class="fa fa-chevron-left"></span></button>
                    <button type="button" class="btn btn-primary" @click="getNext"><span class="fa fa-chevron-right"  ></span></button>
                  </div>
                  <button type="button" class="fc-today-button btn btn-primary"  @click="getToday">today</button>
                </div>
                <div class="fc-toolbar-chunk">
                  <h2 class="fc-toolbar-title" id="fc-dom-311">{{viewTitle}}</h2>
                </div>
                <div class="fc-toolbar-chunk">
                  <div class="row">
                    <div class="col-lg-3">
                      <gpselect
                        :data="auditList"
                        v-model="users"
                        />
                    </div>
                    <div class="col-lg-6">
                      <div class="btn-group">
                        <button type="button" title="month view" :aria-pressed="curView=='dayGridMonth'" class="btn btn-primary" :class="{active:curView=='dayGridMonth'}" @click="monthView">month</button>
                        <button type="button" title="week view" :aria-pressed="curView=='timeGridWeek'" class="btn btn-primary" :class="{active:curView=='timeGridWeek'}" @click="weekView">week</button>
                        <button type="button" title="day view" :aria-pressed="curView=='timeGridDay'" class="btn btn-primary" :class="{active:curView=='timeGridDay'}" @click="dayView">day</button>
                        <button type="button" title="list view" :aria-pressed="curView=='listMonth'" class="btn btn-primary" :class="{active:curView=='listMonth'}" @click="listView">list</button>
                        <button type="button" title="analysis view" :aria-pressed="curView=='analysis'" class="btn btn-primary" :class="{active:curView=='analysis'}" @click="analysisView" v-if="isAdmin">analysis</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="curView!='analysis'" class="app-calendar">
              <!-- Calendar -->
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
            <div v-show="curView=='analysis'">
              <div style="padding: 10px;background-color: #f7f7f7;" class="mb-3">
                <div class="row">
                  <div class="col-lg-6">日期范围：
                    <div class="row">
                          <div class="col-md-6">
                            <date-picker
                              v-model="analysisParams.startDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :first-day-of-week="1"
                              lang="en"
                              placeholder="from"
                              :class="{
                                  'is-invalid': submitted && $v.analysisParams.startDate.$error,
                              }"
                            ></date-picker>
                            <div
                                v-if="submitted && $v.analysisParams.startDate.$error"
                                class="invalid-feedback"
                                >
                                    <span v-if="!$v.analysisParams.startDate.required">
                                      开始日期不能为空.
                                    </span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <date-picker
                              v-model="analysisParams.endDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                              :first-day-of-week="1"
                              lang="en"
                              placeholder="to"
                              :class="{
                                  'is-invalid': submitted && $v.analysisParams.endDate.$error,
                              }"
                            ></date-picker>
                            <div
                                v-if="submitted && $v.analysisParams.endDate.$error"
                                class="invalid-feedback"
                                >
                                    <span v-if="!$v.analysisParams.endDate.required">
                                      期望验证日期不能为空.
                                    </span>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div class="col-lg-2">审核员：
                    <div class="row">
                      <div class="col-lg-12">
                        <gpselect
                          :data="auditList"
                          v-model="analysisParams.auditor"
                          />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">频次：
                    <div class="row">
                      <div class="col-lg-12">
                        <gpselect
                          :data="frequencys"
                          v-model="analysisParams.frequency"
                          />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">&nbsp;
                    <div class="row">
                      <div class="col-lg-12">
                        <b-button variant="success" class="ms-1">提交</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div id="chart1" class="col-lg-6" style="height:400px"></div>
                <div id="chart2" class="col-lg-6" style="height:400px"></div>
              </div>
              
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <b-modal
        centered
        v-model="showModal"
        title="新增日程"
        title-class="text-black font-18"
        body-class="p-4"
        hide-footer
      >
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label for="name" class="form-label">事件名</label>
                <input
                  id="name"
                  v-model="event.title"
                  type="text"
                  class="form-control"
                  placeholder="Insert Event name"
                  :class="{ 'is-invalid': submitted && $v.event.title.$error }"
                />
                <div
                  v-if="submitted && !$v.event.title.required"
                  class="invalid-feedback"
                >
                  This value is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                  <label class="form-label">是否公开</label>
                  <switches v-model="event.isShare" type-bold="false" color="info" style="display: block;"></switches>
              </div>
            </div>
          </div>

          <div class="mt-2 text-end">
            <b-button variant="light" @click="hideModal">Close</b-button>
            <b-button type="submit" variant="success" class="ms-1" 
              >Save</b-button
            >
          </div>
        </form>
      </b-modal>

      <!-- Edit Modal -->
      <b-modal
        centered
        v-model="eventModal"
        title="修改日程"
        title-class="text-black font-18"
        hide-footer
        body-class="p-4"
      >
        <div slot="modal-title">
          修改日程
        </div>
        <form @submit.prevent="editSubmit">
          <div class="row mt-2">
            <div class="col-12">
              <div class="mb-3">
                <label for="name" class="form-label">事件名</label>
                <input
                  id="name"
                  v-model="editevent.title"
                  type="text"
                  class="form-control"
                  placeholder="Insert Event name"
                />
              </div>
            </div>
            <div class="col-12" v-if="!isSystem">
              <div class="mb-3">
                  <label class="form-label">是否公开</label>
                  <switches v-model="editevent.isShare" type-bold="false" color="info" style="display: block;"></switches>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <b-button class="ms-1" variant="danger" @click="confirm" v-if="!isSystem"
                >Delete</b-button
              >
            </div>
            <div class="col-6 text-end">
              <b-button variant="light" @click="closeModal">Close</b-button>

              <b-button class="ms-1" variant="success" @click="editSubmit" v-if="!isSystem"
                >Save</b-button
              >
            </div>
          </div>
        </form>
      </b-modal>
    </div>
    <!-- end row -->
  </Layout>
</template>
