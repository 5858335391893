const categories = [
    {
        name: 'Danger',
        value: 'bg-danger'
    },
    {
        name: 'Success',
        value: 'bg-success'
    },
    {
        name: 'Primary',
        value: 'bg-primary'
    },
    {
        name: 'Info',
        value: 'bg-info'
    },
    {
        name: 'Dark',
        value: 'bg-dark'
    },
    {
        name: 'Warning',
        value: 'bg-warning'
    },
];

//var date = new Date();
//var d = date.getDate();
//var m = date.getMonth();
//var y = date.getFullYear();

const calendarEvents = [
    {
        title: '江苏东源纺织科技实业有限公司-Carol',
        start: new Date('2023-11-01'),
        className: 'bg-warning text-white',
        allDay: true,
    },
    {
        title: '江苏东源纺织科技实业有限公司-Carol',
        start: new Date('2023-11-02'),
        className: 'bg-warning text-white',
        allDay: true,
    },
    {
        title: '江苏东洲服装有限公司-HiggFEM-Carol',
        start: new Date('2023-11-03'),
        allDay: true,
        className: 'bg-warning text-white',
    },
    {
        title: '江苏东洲服装有限公司-HiggFEM-Carol',
        start: new Date('2023-11-04'),
        allDay: true,
        className: 'bg-warning text-white',
    },
    {
        title: '永州湘威运动用品有限公司-HiggFEM-Jack',
        start: new Date('2023-12-31'),
        className: 'bg-success text-white',
        allDay: true,
    },
    {
        title: '永州湘威运动用品有限公司-HiggFEM-Jack',
        start: new Date('2023-11-01'),
        allDay: true,
        className: 'bg-success text-white',
    },
    {
        title: '永州远威运动鞋业有限公司-HiggFEM-Jack',
        start: new Date('2023-11-02'),
        allDay: true,
        className: 'bg-success text-white',
    },
    {
        title: '永州远威运动鞋业有限公司-HiggFEM-Jack',
        start: new Date('2023-08-03'),
        allDay: true,
        className: 'bg-success text-white',
    },
    {
        title: '中山广盛运动器材有限公司-HiggFEM-Paul',
        start: new Date('2023-11-03'),
        allDay: true,
        className: 'bg-info text-white',
    },
    {
        title: '中山广盛运动器材有限公司-HiggFEM-Paul',
        start: new Date('2023-08-04'),
        allDay: true,
        className: 'bg-info text-white',
    },
    {
        title: '杭州三景针织有限公司-HiggFEM-Paul',
        start: new Date('2023-11-08'),
        end: new Date('2023-11-12'),
        allDay: true,
        className: 'bg-info text-white',
    },
];

export { categories, calendarEvents };