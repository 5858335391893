import request from '@/request'

export const queryCalendar = (data) => {
    return request({
        url:'/api-gateway/service/queryCalendar',
        method:'post',
        data,
    });
}
export const saveEvent = (data) => {
    return request({
        url:'/api-gateway/service/saveEvent',
        method:'post',
        data,
    });
}
export const deleteEvent = (id) => {
    return request({
        url:`/api-gateway/service/deleteEvent/${id}`,
        method:'post',
    });
}